<template>
    <div class="mainBox">
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-wrap">
                <label>场景：</label>
                <el-input placeholder="订单编号/收货人姓名/手机号" v-model="select.search" size="small" style="width:360px;" @change="curr=1;getOrderList()">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff"></el-button>
                </el-input>
                <el-select v-model="select.order_method" placeholder="下单方式" clearable style="width:110px" @change="curr=1;getOrderList()">
                    <el-option v-for="item in selectData['下单方式']" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
               
                <el-select v-model="select.status" placeholder="订单状态" filterable clearable style="width:110px" @change="curr=1;getOrderList()">
                    <el-option v-for="(item,index) in selectData['订单状态']" :label="item" :value="index" :key="index"></el-option>
                </el-select>
               
               <!-- <el-select v-model="select.type" placeholder="订单类型" clearable style="width:110px" @change="curr=1;getOrderList()">
                    <el-option v-for="item in selectData['订单类型']" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select> -->
                
                <el-date-picker v-model="select.create_time" style="width:300px;margin-right:auto" size="small" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="筛选" value-format="yyyy-MM-dd" @change="curr=1;getOrderList()">
                </el-date-picker>
                <el-button type="success" block size="mini">订单总金额：{{totalMoney}}元</el-button>
                <!-- <el-button type="primary" block size="mini" @click="exportFun()">导出</el-button> -->
            </div>
        </div>
        <div class="table" style="height:calc( 100% - 175px );">
            <el-table :data="list" ref="barparent" stripe border height="calc( 100% - 41px )">
                <el-table-column align="center" label="下单方式">
                    <template slot-scope="scope">
                        {{scope.row.order_method == 1 ? '预存款' : '线上'}}
                        <div class="item" :style="{width:tableWidth}">
                            订单编号：{{scope.row.order_sn}}
                        	<span style="color:#7B84FF;margin-left:40px">
                        	    订单状态： {{scope.row.status_name}}
                        	</span>
                            <span style="margin-left:40px">创建时间：{{scope.row.create_time}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="商品信息" class="storeInfo" width="300" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-for="g in scope.row.orderDetails" :key="'goods_'+g.id" class="mesSty">
                            <el-image class="pic" :src="domain + g.product_pic" fit="cover" :preview-src-list="[domain + g.product_pic]"></el-image>
                            <div style="width:calc(100% - 105px);">
                                <div class="goodsName" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{g.product_name}}</div>
                                <p><span class="att">规格：</span>{{g.attr_name?g.attr_name : '暂无'}}</p>
                                <p><span class="att">数量：</span>{{g.number}}</p>
								<p><span class="att">预售价：</span>{{(Number(g.price)+Number(g.price_last))* Number(g.number)}}元</p>
								<p><span class="att">定金：</span>{{Number(g.price)*Number(g.number)}}元</p>
								<p><span class="att">尾款：</span>{{Number(g.price_last)*Number(g.number)}}元</p>
								<p><span class="att">小计：</span>{{(Number(g.price)+Number(g.price_last))* Number(g.number)}}元</p>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="postage" align="left" label="配送费" show-overflow-tooltip>
                </el-table-column> -->
               <!-- <el-table-column prop="discount_amount" align="left" width="100" label="优惠券抵扣" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="integral_amount" align="left" label="积分抵扣" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="balance_amount" align="left" label="余额抵扣" show-overflow-tooltip>
                </el-table-column> -->
              <!--  <el-table-column prop="recharge_amount" align="left" width="100" label="会员卡抵扣" show-overflow-tooltip>
                </el-table-column> -->
               <!-- <el-table-column prop="address" align="left" label="商品类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.type==1?'秒杀':scope.row.type==2?'拼团':'普通商品'}}
                    </template>
                </el-table-column> -->
				<el-table-column prop="amount_all" align="left" label="预售价格" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="amount" align="left" label="预付定金" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="amount_last" align="left" label="预付尾款" show-overflow-tooltip>
				</el-table-column>
                <el-table-column prop="amount_payable" align="left" label="应付" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="amount" align="left" label="实付" show-overflow-tooltip>
                </el-table-column>
               
                <el-table-column prop="address" align="left" width="210" label="收货信息" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <!-- <div class="flex regoods">
                            <label>会员卡号：</label>
                            <div>{{scope.row.user && scope.row.user.card  ? scope.row.user.card : '暂无'}}</div>
                        </div> -->
                        <div class="flex regoods">
                            <label>姓名：</label>
                            <div>{{scope.row.name ? scope.row.name : '暂无'}}</div>
                        </div>
						<div class="flex regoods">
						    <label>汽修厂：</label>
						    <div>{{scope.row.user && scope.row.user.garage_name ? scope.row.user.garage_name : '暂无'}}</div>
						</div>
                        <div class="flex regoods">
                            <label>电话：</label>
                            <div>{{scope.row.mobile ? scope.row.mobile : '暂无'}}</div>
                        </div>
                        <div class="flex regoods">
                            <label>地址：</label>
							<div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{scope.row.province+scope.row.city+scope.row.area}}</div>
                        </div>
						<div class="flex regoods">
						    <label>会员身份：</label>
						    <div>{{scope.row.user && scope.row.user.member && scope.row.user.member.name  ? scope.row.user.member.name : '暂无'}}</div>
						</div>
                    </template>
                </el-table-column>
                <el-table-column prop="remarks" align="left" width="210" label="备注" show-overflow-tooltip>
                </el-table-column>
                <el-table-column  fixed="right"  label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <!-- <el-button :disabled="scope.row.order_method == 2 && (scope.row.status == 4 || scope.row.status == 6)  ?  false : true" @click="refund(scope.row.id)" type="text">退款</el-button> -->
                        <!-- <el-button :disabled="scope.row.send_type  == 2 && scope.row.status == 2 ?  false : true" @click="orderOver(scope.row.id)" type="text">订单完成</el-button> -->
                		<el-button  @click="printDiao(scope.row.id)"  type="primary" size="small"  v-if="scope.row.status ==13">打印</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
		
	</div>
</template>

<script>
import { orderListApi, orderStatusListApi, refundApi, remindApi, ysalesorderListApi} from '@/api/order.js';
import { orderOverApi } from '@/api/house.js';
export default {
    data() {
        return {
            selectData: {
				'下单方式': [{
				    id: 1,
				    name: '预存款'
				},{
				    id: 2,
				    name: '线上'
				}],
                '配送方式': [{
                    id: 1,
                    name: '门店配送'
                }, {
                    id: 2,
                    name: '到店自取'
                }],
                '订单类型': [{
                    id: 1,
                    name: '秒杀'
                }, {
                    id: 2,
                    name: '拼团'
                }],
                '发票': [{
                    id: 1,
                    name: '是'
                }, {
                    id: 2,
                    name: '否'
                }]
            },
            select: {
                search: '',
                order_method: '',
                status: '',
                type: '',
                is_invoice: '',
                is_overtime: '',
                send_type: '',
                create_time: []
            },

            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            totalMoney: 0, //订单总金额

            tableWidth: 0,
            list: []
        }
    },

    mounted() {
        this.tableWidth = this.$refs.barparent.bodyWidth;
        //订单列表
        this.getOrderList();
        //订单状态
        this.getOrderStatus();
    },
    methods: {
        //订单列表
        getOrderList: function () {
            ysalesorderListApi({
                curr: this.curr,
                row: this.row,
                ...this.select
            }).then(response => {
                this.totalMoney = response.amount;
                this.list = response.list;
                this.curr = response.curr;
                this.pages = response.pages;
                this.count = response.count;
            })
        },
        //订单状态
        getOrderStatus: function () {
            orderStatusListApi().then(response => {
                this.selectData['订单状态'] = response;
            })
        },
        //到店自取订单完成
        orderOver: function (id) {
            this.$confirm('此操作将完成到店自取订单, 是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                orderOverApi({
                    order_id: id
                }).then(() => {
                    this.getOrderList();
                    this.$message({
                        type: 'success',
                        message: '到店自取订单完成!'
                    });
                })
            }).catch(() => { })
        },
        //退款
        refund: function (id) {
            this.$confirm('此操作将此订单进行退款, 是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                refundApi({
                    order_id: id
                }).then(() => {
                    this.getOrderList();
                    this.$message({
                        type: 'success',
                        message: '已退款!'
                    });
                })
            }).catch(() => { })
        },
        //导出
        exportFun: function () {
            this.$confirm(`确定导出订单吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let url = `/exportExcel/order?param={"search":"${this.select.search}","type":"${this.select.type}","send_type":"${this.select.send_type}","status":"${this.select.status}","is_overtime":"${this.select.is_overtime}","order_method":"${this.select.order_method}","is_invoice":"${this.select.is_invoice}","create_time":${JSON.stringify(this.select.create_time ? this.select.create_time : [])}}`;
                location.href = this.domain + url;
                loading.close();
            }).catch(() => {
                this.$message('已取消当前操作')
            })
        },
        //翻页
        currentChange: function (curr) {
            this.curr = curr;
            this.getOrderList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.row = row;
            this.getOrderList();
        },
		//新增  预售功能
		setupSwitch: function(row,state) {
		    remindApi({
		        id:row,
		        is:state
		    }).then(()=>{
		        this.$message({
		            type: 'success',
		            message: '设置成功!'
		        });
		        this.getOrderList();
		    })
		},
		
		// 新页面打印
		printDiao: function(id) {
			let url = this.$router.resolve({
				path:"./printOrder",
				query:{
					id:id
				}
			})
			window.open(url.href,'_blank')//注意，需要加hr
			
			  
		},
		
		
		
    },
}
</script>

<style scoped>
    .el-table--enable-row-transition >>> .el-table__body td {
        padding-top: 5%;
        padding-bottom:1%;
    }
    :v-deep .el-table tr {
        position: relative;
        width: 100%;
    }
    .item {
        background: #f2f3ff;
        padding: 10% 0;
        position: absolute;
        top: 0;
        left: 0px !important;
        z-index: 4;
        text-align: left;
        padding-left: 10px;
        box-sizing: border-box;
    }
    .mesSty .pic {
        width: 80px;
        height: 80px;
        margin: 0 10px;
        object-fit: cover;
        border-radius: 3px;
        display: block;
    }
    .mesSty {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebeef5;
    }
    
    .mesSty:last-child {
        border: none;
    }
</style>